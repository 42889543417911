import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Stars = ({ rating }) => {
  return (
    <div style={{ display: "flex", gap: "4px", marginBottom: "24px" }}>
      {Array.apply(null, { length: Math.ceil(rating) }).map((e, i) => (
        <FontAwesomeIcon key={`star-${i}`} icon={faStar} color="#382ebe" />
      ))}
    </div>
  );
};

Stars.propTypes = {
  rating: PropTypes.number,
};

Stars.defaultProps = {
  rating: 5,
};

export default Stars;
