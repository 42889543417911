import React from "react";
import Slider from "react-slick";
import { withPrefix } from "gatsby";

import Layout from "../../components/Layout";
import Stars from "../../components/Stars";

const settings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  className: "slider slider-three",
};

const ThanksPage = () => (
  <Layout hideBookButton>
    <div className="banner">
      <div className="content banner-c thanks">
        <div className="banner-wr">
          <div className="banner-txt thanks">
            <h1>Thanks for your enquiry</h1>
            <p className="thanks-p">
              One of our team members will be in touch shortly.
            </p>
          </div>
        </div>
        <div className="what">
          <div className="testimonials">
            <h3 className="test-heading">
              What tax agents and accountants think about AuditCover’s tax audit
              insurance
            </h3>
            <Slider {...settings}>
              <div>
                <div className="slide">
                  <Stars />
                  <p className="test-p">
                    The AuditCover team are legends.  Easy to work with,
                    incredibly responsive and helpful and solved a real pain
                    point for All In Advisory with offering AuditCover to our
                    clients.  Their processes are so much more automated and
                    seamless than other providers in this space.  Breath of
                    fresh air in fact! and so excited for where they can take
                    this product.
                  </p>
                  <div className="author-wr">
                    <div className="div-block">
                      <img
                        src={`${withPrefix("/")}images/author-all-in.png`}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="author">
                      Aly Garrett, <br />
                      All In Advisory, <br />
                      Adelaide
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="slide">
                  <Stars />
                  <p className="test-p">
                    Our firm transitioned to AuditCover this year and the
                    process has been seamless. They have taken care of all the
                    administrative side of things, are available to assist with
                    any queries in a timely manner. We’re no longer having to
                    deal with client payments and the self-quote option for
                    clients has brought a new level of ease for getting our
                    clients the right cover for them. Our take up rates have
                    increased as well which confirms to us moving to AuditCover
                    was the right choice not only for our firm but also our
                    clients.
                  </p>
                  <div className="author-wr">
                    <div className="div-block">
                      <img
                        src={`${withPrefix("/")}images/author-mja.png`}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="author">
                      MJA <br />
                      Business <br />
                      Solutions
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="slide">
                  <Stars />
                  <p className="test-p">
                    The team at AuditCover are awesome to work with. Our
                    previous experience with taking up audit insurance coverage
                    for clients has been very manual but AuditCover made it
                    really easy for us and our clients to set up and manage. The
                    portal is user friendly and it&#x27;s really clear and easy
                    to understand. Highly recommend.
                  </p>
                  <div className="author-wr">
                    <div className="div-block">
                      <img
                        src={`${withPrefix("/")}images/author-fs.png`}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="author">
                      Ahmad <br />
                      Beydoun
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="slide">
                  <Stars />
                  <p className="test-p">
                    It has been a pleasure working alongside AuditCover team.
                    The whole process took next to no effort on our side and we
                    were guided and supported throughout the process for
                    whatever little we had to do. It has been a win-win for all.{" "}
                  </p>
                  <div className="author-wr">
                    <div className="div-block">
                      <img
                        src={`${withPrefix("/")}images/AVS-advrisors.jpg`}
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="author">
                      Vakel
                      <br />
                      Dhillon,
                      <br />
                      AVS Advisors{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ThanksPage;
